
$(document).ready(function(){
  $("#absence_absence_reason").on("change", function(){
    absence = $(this).val();
    if(absence == "Other")
    {     
      $(".other-reason").removeClass("hidden");
    }
    else{
      $(".other-reason").addClass("hidden");
    }  
  });

});